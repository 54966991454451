<template>
    <!-- ======= Values Section ======= -->
    <section id="whatwedo" class="values">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
        <!-- <h2>Our Values</h2> -->
        <p>What We Do</p>
        </header>

        <div class="row">

        <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="box">
            <img src="@/assets/img/values-1.png" class="img-fluid" alt="">
            <h3>Global Listings</h3>
            <p>Up and Coming curates a list of vetted properties in Japan, Canada, the U.S.A. and Vietnam utilizing proprietary machine learning and AI algorithms.</p>
            </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div class="box">
            <img src="@/assets/img/values-2.png" class="img-fluid" alt="">
            <h3>Investment</h3>
            <p>Invest in a portion or entire property. Ownership will be formalized through a U.S.-based entity, ensuring protection under U.S. legal frameworks.</p>
            </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
            <div class="box">
            <img src="@/assets/img/values-3.png" class="img-fluid" alt="">
            <h3>Full Management</h3>
            <p>Up and Coming will manage and rent out your properties, allowing you to earn rental dividends and decide when to sell.</p>
            </div>
        </div>

        </div>

    </div>

    </section><!-- End Values Section -->
</template>