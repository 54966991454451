<template>
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
        <div class="container" data-aos="fade-up">
            <div class="row gx-0">

            <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                <div class="content">
                <h3>Who We Are</h3>
                <h2>Buying real estate globally is difficult. Let's change it.</h2>
                <p>
                    We are a group of passionate real estate investors and technologists 
                    who experienced the pain points of buying properties remotely. 
                    We decided to change it.
                </p>
                <div class="text-center text-lg-start">
                    <a href="#howitworks" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>How It Works</span>
                    <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
                </div>
            </div>

            <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                <img src="@/assets/img/team.jpg" class="img-fluid" alt="">
            </div>

            </div>
        </div>
    </section><!-- End About Section -->
</template>

<style scoped>
    h3 {
        font-size: 25px;
    }
    h2 {
        font-size: 35px;
    }
    /* img {
        width: 70%;
        height: auto;
    } */
</style>