import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDU3fOgPXnC13hdwUNLywCu_s7oQxIUkuk",
  authDomain: "bidllion.firebaseapp.com",
  databaseURL: "https://bidllion-default-rtdb.firebaseio.com",
  projectId: "bidllion",
  storageBucket: "bidllion.appspot.com",
  messagingSenderId: "98596224779",
  appId: "1:98596224779:web:e2f86c1d1a797b9ce06c0e",
  measurementId: "G-M07NQXG1XZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
