import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TermsOfService from '../views/TermsOfService.vue'
import ListingDetail from '../views/ListingDetail.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: TermsOfService
  },
  { path: '/listings/:id',
    name: 'ListingDetail',
    component: ListingDetail 
  },
  { path: '/404', component: NotFound },  
  { 
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
