<template>
    <section id="listings" class="recent-blog-posts">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <p>Listings</p>
        </header>

        <div class="row justify-content-center">

          <div class="col-lg-6 current-listing" v-for="(listing, index) in listings" :key="index">
            <div class="post-box">
              <div class="post-img">
                <img v-bind:src="listing.photo" class="img-fluid" alt="">
                </div>
              <span class="post-date">{{listing.beds}} Beds | {{listing.baths}} Baths | {{listing.size}} Sqft | {{listing.location}}</span>
              <h3 class="post-title">SOLD - {{listing.price}} (1% ownership)</h3>
              <ul>
                  <li>{{listing.highlight1}}</li>
                  <li>{{listing.highlight2}}</li>
                  <li>{{listing.highlight3}}</li>
              </ul>
              <a :href="'/listings/' + index" class="readmore stretched-link mt-auto"><span>More Detail</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End Recent Blog Posts Section -->
</template>

<script>
export default {
  data() {
    return {
      listings: [
        {
          id: 1,
          price: '$2,240 USD',
          beds: '3',
          baths: '2',
          size: '1,200',
          location: 'Hanoi, Vietnam',
          highlight1: '29% year-over-year growth in 2021',
          highlight2: '3% est. yearly rent revenue',
          highlight3: 'Fastest growing city in Vietnam',
          photo: require('@/assets/img/lacasta/amenity.jpeg'),
        },
        {
          id: 2,
          price: '$12,000 USD',
          beds: '5',
          baths: '2.75',
          size: '2,170',
          location: 'Bellevue, WA, USA',
          highlight1: '27% year-over-year growth in 2021',
          highlight2: '3.6% ROI in Rent',
          highlight3: '100% occupancy rate since 2018',
          photo: require('@/assets/img/bellevue/1.jpeg'),
        },
      ]
    }
  },
}
</script>

<style scoped>
    img {
        width: 100%;
    }

    .banner-current {
        width: 200px;
        position: absolute;
        top: 35px;
        right: -45px;
        transform: rotate(45deg);
        z-index: 1;
        font-size: 16px;
        padding: 5px 0 5px 0;
        background: #4154f1;
        color: #fff;
        text-align: center;
    }

    .current-listing {
      margin: 16px 0 16px 0;
    }
</style>