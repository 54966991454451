<template>
  <div class="404">
    <section id="404" class="align-items-center">
        <div class="container">
            <h1>404 Page Not Found</h1>
        </div>
    </section>
  </div>
</template>

<style scoped>
    h1 {
        margin-top: 128px;
        margin-bottom: 128px;
    }
</style>