<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top">
      <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

        <a href="/" class="logo d-flex align-items-center">
          <img src="@/assets/img/logo.png" alt="">
          <span>Up&Coming</span>
        </a>        
        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link scrollto active" href="/#hero">Home</a></li>
            <li><a class="nav-link scrollto" href="/#contact">Contact</a></li>
            <li><a class="getstarted scrollto" href="/#listings">See Listings</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
</template>

<style scoped>
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
  }
</style>