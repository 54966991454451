<template>
    <section id="howitworks" class="features">
      <div class="container" data-aos="fade-up">
        <div class="row feature-icons" data-aos="fade-up">
          <h3>How It Works</h3>

          <div class="row">

            <div class="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
              <img src="@/assets/img/features-3.png" class="img-fluid p-4" alt="">
            </div>

            <div class="col-xl-8 d-flex content">
              <div class="row align-self-center gy-4">

                <div class="col-md-6 icon-box" data-aos="fade-up">
                  <i class="ri-community-line"></i>
                  <div>
                    <h4>1. Schedule a Consultation</h4>
                    <p>We'd love to learn about your investment requirements and ensure your eligibility under U.S. investment regulations.</p>
                  </div>
                </div>

                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                  <i class="ri-account-circle-line"></i>
                  <div>
                    <h4>2. Choose Your Property</h4>
                    <p>See the list of available properties and choose the properties that you would like to invest in.</p>
                  </div>
                </div>

                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                  <i class="ri-coins-line"></i>
                  <div>
                    <h4>3. Purchase and Sign</h4>
                    <p>Confirm the property you would like to purchase and a DocuSign purchase agreement will be sent to you. Sign and you will be the owner of the property invested in.</p>
                  </div>
                </div>

                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                  <i class="ri-edit-2-line"></i>
                  <div>
                    <h4>4. Fund Your Investment</h4>
                    <p>Once you signed the purchase documents, you will be able to wire transfer the fund to Up & Coming.</p>
                  </div>
                </div>

                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                  <i class="ri-file-list-3-line"></i>
                  <div>
                    <h4>5. Receive Your Ownership</h4>
                    <p>The documentatioins you sign via DocuSign will represent the ownership of the property.</p>
                  </div>
                </div>

                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                  <i class="ri-hand-coin-line"></i>
                  <div>
                    <h4>6. Hold or Sell</h4>
                    <p>The properties purchased will be rented out and fully managed. You will receive the rent as dividends periodically along with the associated tax documents, namely K1 and 1099 forms.</p>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div><!-- End Feature Icons -->

      </div>

    </section><!-- End Features Section -->
</template>