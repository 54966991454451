<template>
  <div class="home">
    <HeroSection />
    <About />
    <WhatWeDo />
    <HowItWorks />
    <CurrentListings />
    <Faq />
    <Contact />
  </div>
</template>

<script>
// @ is an alias to /src
import About from '@/components/About.vue'
import Contact from '@/components/Contact.vue'
import CurrentListings from '@/components/CurrentListings.vue'
import Faq from '@/components/Faq.vue'
import HeroSection from '@/components/HeroSection.vue'
import HowItWorks from '@/components/HowItWorks.vue'
import Team from '@/components/Team.vue'
import WhatWeDo from '@/components/WhatWeDo.vue'

export default {
  name: 'Home',
  components: {
    About,
    Contact,
    CurrentListings,
    Faq,
    HowItWorks,
    HeroSection,
    Team,
    WhatWeDo
  }
}
</script>
