<template>
    <!-- ======= F.A.Q Section ======= -->
      <section id="faq" class="faq">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <!-- <h2>F.A.Q</h2> -->
            <p>Frequently Asked Questions</p>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <!-- F.A.Q List 1-->
              <div class="accordion accordion-flush" id="faqlist1">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                      How can I start investing?
                    </button>
                  </h2>
                  <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div class="accordion-body">
                      Create and fund your Up and Coming account. Then, you will be able to start co-investing in the listings of your desire!
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                      How do I sell the property?
                    </button>
                  </h2>
                  <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div class="accordion-body">
                      Owners of the property can initiate the voting process to sell the property or the property will be sold 5 years after closing.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                      Are the shares refundable?
                    </button>
                  </h2>
                  <div id="faq-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div class="accordion-body">
                      No, the share legally represents your ownership in the property. However, you can resell your share to other interested buyers.
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-lg-6">

              <!-- F.A.Q List 2-->
              <div class="accordion accordion-flush" id="faqlist2">

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                      Can I resell my shares to other buyers?
                    </button>
                  </h2>
                  <div id="faq2-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div class="accordion-body">
                      Yes, you are more than welcome to do so if you decide not to wait for the automatic property sale in 5 years.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                      Can I see the property in person before the purchase?
                    </button>
                  </h2>
                  <div id="faq2-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div class="accordion-body">
                      Yes, of course. We will be more than happy to schedule a tour for you with a local agent.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                      How do I deal with the tax in Vietnam?
                    </button>
                  </h2>
                  <div id="faq2-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div class="accordion-body">
                      All tax oversea will be handled by Up and Coming. At the time of resale, Up and Coming will use the fund obtained from the sale to pay off any tax and fees imposed in Vietnam before distributing the fund to the co-owners so the co-owners will only need to handle the capital gain tax on their own.
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>

      </section><!-- End F.A.Q Section -->
</template>