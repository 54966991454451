<template>
    <section id="portfolio-details" class="portfolio-details">
      <div class="container">
        <div class="row gy-4"> <!-- Pictures -->
          <div class="col-lg-12">
            <div class="portfolio-details-slider swiper">
              <div class="swiper-wrapper align-items-center">
                <div class="swiper-slide" v-for="(photo, index) in listing.photos" :key="index">
                  <a v-bind:href="photo" data-gallery="portfolioGallery" class="portfokio-lightbox">
                    <img v-bind:src="photo" alt="">
                    </a>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div> 
          </div>
        </div> <!-- end of Pictures -->

        <div class="flex-column flex-md-row">
            <div class="d-flex flex-column flex-md-row">
              <div class="portfolio-description">
                <h2 class="mb-2">{{shortenPrice(listing.price, 2)}} | {{numbWithComma(listing.size)}} Sqft | {{listing.location}} (SOLD)</h2>
                <p>{{listing.beds}} Beds ∙ {{listing.baths}} Baths ∙ {{(listing.latestGrowthRate * 100).toFixed(0)}}% YOY growth in 2021</p>
                <hr class="line-break my-5" />
                <h5><strong>Home Highlight</strong></h5>
                <div class="d-flex flex-column flex-md-row">
                  <ul class="w-50">
                    <li v-for="(highlight, index) in listing.hightlights.slice(0, listing.hightlights.length/2)" :key="index"><p class="my-1">{{highlight}}</p></li>
                    <li class="my-1">Address: <a v-bind:href="listing.google_map"  target="_blank">{{listing.address}}</a></li>
                    <li class="my-1" v-if="listing.redfin">MLS Info: <a v-bind:href="listing.redfin"  target="_blank">Redfin</a></li>
                  </ul>
                  <ul>
                    <li v-for="(highlight, index) in listing.hightlights.slice(listing.hightlights.length/2, listing.hightlights.length)" :key="index"><p class="my-1">{{highlight}}</p></li>
                  </ul>
                </div>
              </div> <!-- End of Column 1 of Home Info -->

              <!-- Column 2: Purchase Price Calculation -->
              <div class="portfolio-info my-3 d-none d-md-block" id="price-cal-side">
                <h4>Purchase Price</h4>

                  <h5 class="mt-5 mb-3">Buy <strong class="text-primary">{{ownership}}%</strong> of the Property</h5>
                  <div class="d-flex mb-3">
                    <span>1% </span>
                    <form class="range-field flex-fill mx-3"><input class="w-100" type="range" min="1" v-bind:max="listing.maxOwnership" v-model="ownership" /></form>
                    <span> {{listing.maxOwnership}}% Max</span>
                  </div>
                <!-- <div class="d-flex flex-column justify-content-between h-100"> -->
                  <div class="d-flex flex-column justify-content-between">
                  <div class="line-items">
                    <div class="d-flex justify-content-between mt-3">
                      <span>Home Equity Cost</span><span>${{Intl.NumberFormat().format(ownership/100 * listing.price)}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span>Closing Cost ({{(listing.closingCostRate * 100).toFixed(0)}}%)</span><span class="align-items-end">${{Intl.NumberFormat().format(ownership/100 * listing.price * listing.closingCostRate)}} USD</span>
                    </div>
                  </div>
                  <div class="total mb-4 mt-2">
                    <hr class="line-break mt-3" />
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Ownership</span><span>{{ownership}}%</span>
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                      <!-- <span>Total Price</span><span class="total">${{Intl.NumberFormat().format(ownership/100 * listing.price * (1 + listing.closingCostRate))}} USD</span> -->
                      <span>Total Price</span><span class="total">{{formatPrice(purchasePrice, 0)}}</span>
                    </div>
                  </div>
                </div>
              </div> <!-- End of Column 2: Purchase Price Column on Medium+ Screen -->
            </div> <!-- End of 2 Column Split -->
            <hr class="line-break my-5" />
            <!-- Description Sections -->
            <div class="mt-5" v-for="(description, index) in listing.descriptions" :key="index">
              <h5 v-if="description.title"><strong>{{description.title}}</strong></h5>
              <p class="my-3">
                {{description.content}}
              </p>
              <img v-if="description.photo" v-bind:src="description.photo" alt="">
            </div>
            <!-- Ownership Slider -->
            <div class="mt-5 mb-3">
              <h3>Buy <strong class="text-primary">{{ownership}}%</strong> of the Property</h3>
              <div class="d-flex">
                <span>1% </span>
                <form class="range-field flex-fill mx-3"><input class="w-100" type="range" min="1" v-bind:max="listing.maxOwnership" v-model="ownership" /></form>
                <span> {{listing.maxOwnership}}% Max</span>
              </div>
            </div>
            <!-- Calculation Cards -->
            <div class="d-flex flex-lg-row flex-column justify-content-between">
              <!-- Purchase Calculation -->
              <div class="portfolio-info w-100 my-3 d-block d-sm-none d-xs-none">
                <h4>Purchase Price</h4>
                <div class="d-flex flex-column justify-content-between h-100">
                  <div class="line-items">
                    <div class="d-flex justify-content-between mt-3">
                      <span>Home Equity Cost</span><span>${{Intl.NumberFormat().format(ownership/100 * listing.price)}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span>Closing Cost ({{(listing.closingCostRate * 100).toFixed(0)}}%)</span><span class="align-items-end">${{Intl.NumberFormat().format(ownership/100 * listing.price * listing.closingCostRate)}} USD</span>
                    </div>
                  </div>
                  <div class="total mb-4 mt-2">
                    <hr class="line-break mt-3" />
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Ownership</span><span>{{ownership}}%</span>
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Price</span><span class="total">{{formatPrice(purchasePrice, 0)}}</span>
                    </div>
                  </div>
                </div>
              </div> <!-- End of Purchase Price Column on sm,xs screen -->
              <!-- Total Earning Calculation -->
              <div class="portfolio-info w-100 my-3">
                <h4>Total Earning in 5 Years</h4>
                <div class="d-flex flex-column justify-content-between h-100">
                  <div class="line-items">  
                    <div class="d-flex justify-content-between mt-3">
                      <span>Projected Sale Price</span><span>${{Intl.NumberFormat().format((compoundGrowth(listing.price, 5, listing.avgGrowthRate) * ownership/100).toFixed(0))}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                      <span>Total Rent Earning (60 months)</span><span class="align-items-end">{{formatPrice((totalMoRent(ownership, listing.rent, listing.propertyTax, listing.rentalTaxRate, listing.repair, listing.insurance, listing.managementRate)*60), 0)}}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                      <span>Selling Cost ({{listing.sellingCostRate*100}}%)</span><span>- {{formatPrice(year5SalePrice * listing.sellingCostRate, 0)}}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                      <span>Original Purchase Price</span><span>- {{formatPrice(ownership/100 * listing.price * (1 + listing.closingCostRate), 0)}}</span>
                    </div>
                  </div>
                  <div class="total mb-4 mt-2">
                    <hr class="line-break mt-3" />
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Ownership</span><span>{{ownership}}%</span>
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Earning in 5 Years</span><span class="total">{{formatPrice(year5SalePrice + monthlyRentProfit * 60 - purchasePrice - year5SalePrice * listing.sellingCostRate, 0)}}</span>
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Return</span><span class="total">{{((year5SalePrice + monthlyRentProfit * 60 - purchasePrice - year5SalePrice * listing.sellingCostRate)/purchasePrice * 100).toFixed(1)}}%</span>
                    </div>
                  </div>
                </div>
              </div> <!-- End of Total Earning Calculation -->
              <!-- Home Value Calculation -->
              <div class="portfolio-info w-100 line-items my-3">
                <h4>Price Growth in 5 Years</h4>
                <div class="d-flex flex-column justify-content-between h-100">
                  <div class="line-items">
                    <div class="d-flex justify-content-between mt-3">
                      <span class="line-item">Current</span><span class="line-item">{{shortenPrice(listing.price, 2)}}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-3" v-for="year in 5" :key="year">
                      <span class="line-item">Year {{year}}</span><span class="line-item">{{shortenPrice(compoundGrowth(listing.price, year, listing.avgGrowthRate), 2)}}</span>
                    </div>
                    <p class="mt-4"><small>* 5 year avg. annual growth rate in {{listing.location}} is {{listing.avgGrowthRate * 100}}%.</small></p>
                  </div> <!-- end of line-items -->
                  
                  <div class="total mb-4 mt-2">
                    <hr class="line-break mt-3" />
                    <div class="mt-3 d-flex justify-content-between ">
                      <span class="total">Total Ownership</span><span class="total">{{ownership}}%</span>
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                      <span class="total">Equity Price in 5 Years</span><span class="total">{{formatPrice(year5SalePrice, 0)}}</span>
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                      <span class="total">Total Growth</span><span class="total">{{((year5SalePrice/(listing.price * ownership/100) - 1) * 100).toFixed(1)}}%</span>
                    </div>
                  </div> <!-- end of total -->
                </div>
              </div> <!-- End Home Value Calculation -->
              <!-- Rent Income Calculation -->
              <div class="portfolio-info w-100 line-items my-3">
                <h4>Monthly Rental Income</h4>
                <div class="d-flex flex-column justify-content-between h-100">
                  <div class="line-items">
                    <div class="d-flex justify-content-between mt-3">
                      <span>Monthly Rent Revenue</span><span>${{Intl.NumberFormat().format((ownership/100 * listing.rent).toFixed(2))}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span>Repair Reserve</span><span class="align-items-end">- ${{Intl.NumberFormat().format((ownership/100 * listing.repair/12).toFixed(2))}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span>Home Owner Insurance</span><span class="align-items-end">- ${{Intl.NumberFormat().format((ownership/100 * listing.insurance/12).toFixed(2))}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2" v-if="listing.propertyTax > 0">
                      <span>Property Tax</span><span class="align-items-end">- ${{Intl.NumberFormat().format((ownership/100 * listing.propertyTax/12).toFixed(2))}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2" v-if="listing.rentalTaxRate > 0">
                      <span>Property Rental Tax</span><span class="align-items-end">- ${{Intl.NumberFormat().format((ownership/100 * listing.rent * listing.rentalTaxRate).toFixed(2))}} USD</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span>Property Management Fee</span><span class="align-items-end">- ${{Intl.NumberFormat().format((ownership/100 * listing.rent * 0.1).toFixed(2))}} USD</span>
                    </div>
                  </div>
                  <div class="total mb-4 mt-2">
                    <hr class="line-break mt-3" />
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Ownership</span><span>{{ownership}}%</span>
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                      <span>Total Monthly Income</span><span class="total">{{formatPrice(monthlyRentProfit,2)}}</span>
                    </div>
                  </div>
                </div>
              </div> <!-- End Rent Calculation -->
            </div> <!-- End of Calculation Cards -->
        </div>
      </div>
    </section><!-- End Portfolio Details Section -->
</template>

<script>
// Import static assets
import Swiper from '@/assets/vendor/swiper/swiper-bundle.min.js';
import Isotope from '@/assets/vendor/isotope-layout/isotope.pkgd.min.js';
import AOS from '@/assets/vendor/aos/aos.js';
import GLightbox from '@/assets/vendor/glightbox/js/glightbox.min.js';
import GridPhotos from '@/components/GridPhotos.vue'

export default {
  components: {
    GridPhotos
  },
  data() {
    return {
      ownership: 1, // default is 1% of ownership
      listings: [
        {
          id: 0,
          price: 224000,
          beds: '3',
          baths: '2',
          size: '1200',
          location: 'Hanoi, Vietnam',
          latestGrowthRate: 0.29,
          avgGrowthRate: 0.11,
          closingCostRate: 0.05,
          sellingCostRate: 0.06,
          rent: 435,
          insurance: 1000,
          repair: 2000,
          rentalTaxRate: 0.15,
          propertyTax: 0,
          managementRate: 0.1,
          maxOwnership: 100,
          hightlights: [
            '16% year-over-year growth in 2023',
            '3% ROI in rent',
            'Apple iPad and Macbook manufacturing is within 50 minutes drive',
            'Google Pixel Phone manufacturing is within 50 minutes drive',
            'Samsung Galaxy Phone manufacturing is within 50 minutes drive',
            '15 mins drive to Downtown Hanoi',
            'Skytrain station is being built with walking distance',
          ],
          descriptions: [
            {
              title: "About this Home",
              content: "La Casta Tower urban area is located in Van Phu urban area, Phu La ward, Ha Dong district, Hanoi city. This is a project designed and planned by the Korean Ipyung Group. La Casta Tower project is designed in a minimalist neoclassical style, bringing sophistication and modernity. Especially, La Casta's apartments are fully equipped with high-class appliances and Korean standard amenities.",
            },
            {
              title: "Location Highlight",
              content: "La Casta Tower project has the advantage of convenient transportation network connecting from the project to the city center. La Casta Tower apartment building is located on Le Trong Tan street with a 42m wide road extending to To Huu street, Thang Long Boulevard. In addition, it only takes 5 minutes to walk from La Casta Apartment to La Khe Metro station and only takes about 15 minutes to the center of Hanoi City. In addition, the bus rapid transit (BRT) route connecting the city center to La Casta Tower urban area has been put into operation, which is very convenient for residents to participate in traffic.",
              photo: require('@/assets/img/lacasta/location.jpeg'),
            },
            {
              title: "Amenity",
              content: "Investor Hibrand of La Casta Tower Van Phu Urban Area has planned a large park for the urban area with many trees around. Central Park is located in the middle of the planning area, with an area of ​​​​about 1ha. The inside is planted with a large number of shade trees, green grass, especially the central lake, creating a very natural scene. Thanks to the green ecosystem, the air in La Casta Tower Van Phu Urban Area is always fresh, summer is cool, winter is warm, autumn leaves fall forming a romantic scene, spring is reaching full of life.",
              photo: require('@/assets/img/lacasta/amenity.jpeg'),
            }
          ],
          address: 'Khu đô thị Văn Phú, Hà Đông, Hanoi, Vietnam',
          google_map: 'https://goo.gl/maps/FaVZ2zuSxSssDxJv7',
          photos: [
            require('@/assets/img/lacasta/amenity.jpeg'),
            require('@/assets/img/lacasta/1.jpeg'),
            require('@/assets/img/lacasta/2.jpeg'),
            require('@/assets/img/lacasta/3.jpeg'),
            require('@/assets/img/lacasta/4.jpeg'),
            require('@/assets/img/lacasta/5.jpeg'),
            require('@/assets/img/lacasta/6.jpeg'),
            require('@/assets/img/lacasta/7.jpeg'),
            require('@/assets/img/lacasta/8.jpeg'),
            require('@/assets/img/lacasta/9.jpeg'),
            require('@/assets/img/lacasta/location.jpeg'),
          ],
        },
        {
          id: 1,
          price: 1200000,
          beds: '5',
          baths: '2.75',
          size: '2170',
          rent: 3600,
          insurance: 2000,
          repair: 5000,
          propertyTax: 7233,
          rentalTaxRate: 0,
          sellingCostRate: 0.06,
          managementRate: 0.1,
          location: 'Bellevue, WA, USA',
          latestGrowthRate: 0.27,
          avgGrowthRate: 0.0868,
          closingCostRate: 0.03,
          maxOwnership: 35, // Only 35% of the property is for sale.
          hightlights: [
            '23.5% year-over-year growth in 2023',
            '3.6% ROI in rent',
            '100% occupancy rate since 2018',
            'Fully remodeled in 2018',
            '20 mins drive to Microsoft HQ, Amazon HQ, Expedia HQ, Google and other office buildings',
            '10 mins drive to Downtown Bellevue',
          ],
          descriptions: [
            {
              title: "About this Home",
              content: "This beauty, with modern updates galore, has it all and then some. 5 bedrooms and an office, 2.75 bathrooms, stainless steel appliances, quartz counters, 5 year old roof, and a huge backyard with RV parking on this 0.25 acre lot. Relax in the sunroom with your morning coffee just up the hill from Lake WA in desirable Newport Hills. You'll enjoy peaceful living with nearby access to I405 and all the amenities you could want- parks, shopping, coffee, neighborhood eats, Newcastle Golf, and so much more.",
            },
          ],
          address: '6531 117th Ave SE, Bellevue, WA, USA 98006',
          redfin: 'https://www.redfin.com/WA/Bellevue/6531-117th-Ave-SE-98006/home/417563',
          google_map: 'https://goo.gl/maps/gcZ962zp3REKgkHw6',
          photos: [
            require('@/assets/img/bellevue/1.jpeg'),
            require('@/assets/img/bellevue/2.jpeg'),
            require('@/assets/img/bellevue/3.jpeg'),
            require('@/assets/img/bellevue/4.jpeg'),
            require('@/assets/img/bellevue/5.jpeg'),
            require('@/assets/img/bellevue/6.jpeg'),
            require('@/assets/img/bellevue/7.jpeg'),
            require('@/assets/img/bellevue/8.jpeg'),
            require('@/assets/img/bellevue/9.jpeg'),
            require('@/assets/img/bellevue/10.jpeg'),
            require('@/assets/img/bellevue/11.jpeg'),
            require('@/assets/img/bellevue/12.jpeg'),
            require('@/assets/img/bellevue/13.jpeg'),
            require('@/assets/img/bellevue/14.jpeg'),
            require('@/assets/img/bellevue/15.jpeg'),
            require('@/assets/img/bellevue/16.jpeg'),
            require('@/assets/img/bellevue/17.jpeg'),
            require('@/assets/img/bellevue/18.jpeg'),
            require('@/assets/img/bellevue/19.jpeg'),
            require('@/assets/img/bellevue/20.jpeg'),
            require('@/assets/img/bellevue/21.jpeg'),
          ],
        },
      ],
    }
  },
  computed: {
    listing: function() {
        if (this.$route.params.id < this.listings.length) {
          return this.listings[this.$route.params.id];  //it's a number
        }
        // return this.listings[1];
        return this.listings[0];
    },
    year5SalePrice: function() {
      const listing = this.$route.params.id < this.listings.length? this.listings[this.$route.params.id] : this.listings[0];
      return this.compoundGrowth(listing.price, 5, listing.avgGrowthRate) * this.ownership/100
    },
    monthlyRentProfit: function() {
      const listing = this.$route.params.id < this.listings.length? this.listings[this.$route.params.id] : this.listings[0];
      return this.ownership/100 * (listing.rent * (1 - listing.managementRate - listing.rentalTaxRate) - listing.propertyTax/12 - listing.insurance/12 - listing.repair/12);
    },
    purchasePrice: function() {
      const listing = this.$route.params.id < this.listings.length? this.listings[this.$route.params.id] : this.listings[0];
      return this.ownership/100 * listing.price * (1 + listing.closingCostRate);
    },
  },
  mounted () {
    this.run();
  },
  methods: {
    formatPrice: (numb, decimal) => {
      return '$' + Intl.NumberFormat().format(numb.toFixed(decimal)) + ' USD';
    },
    totalEarning: (listingPrice, ownership, avgGrowthRate, rent, insurance, repair, propertyTax, rentalTaxRate, managementRate, closingCostRate) => {
      return year5SalePrice // Sale Price
        + totalMoRent(ownership, rent, propertyTax, rentalTaxRate, repair, insurance, managementRate) * 60 // Total Rent
        - ownership/100 * listingPrice * (1 + closingCostRate); // Purchase Cost
    },
    numbWithComma: (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    shortenPrice: (num, digits) => {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });
      return item ? '$'+(num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol + " USD": "0";
    },
    totalMoRent: (ownership, rent, tax, rentalTaxRate, repair, insurance, managementRate) => {
      return ownership/100 * (rent * (1 - managementRate - rentalTaxRate) - tax/12 - insurance/12 - repair/12);
    },
    compoundGrowth: (principle, duration, rate) => {
      return principle * Math.pow((1 + rate), duration);
    },
    run: () => {
      "use strict";

      /**
       * Easy selector helper function
       */
      const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }

      /**
       * Easy event listener function
       */
      const on = (type, el, listener, all = false) => {
        if (all) {
          select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
          select(el, all).addEventListener(type, listener)
        }
      }

      /**
       * Portfolio details slider
       */
      new Swiper('.portfolio-details-slider', {
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      });

      /**
       * Porfolio isotope and filter
       */
      window.addEventListener('load', () => {
        let portfolioContainer = select('.portfolio-container');
        if (portfolioContainer) {
          let portfolioIsotope = new Isotope(portfolioContainer, {
            itemSelector: '.portfolio-item',
            layoutMode: 'fitRows'
          });

          let portfolioFilters = select('#portfolio-flters li', true);

          on('click', '#portfolio-flters li', function(e) {
            e.preventDefault();
            portfolioFilters.forEach(function(el) {
              el.classList.remove('filter-active');
            });
            this.classList.add('filter-active');

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter')
            });
            aos_init();
          }, true);
        }

      });

      /**
       * Initiate portfolio lightbox 
       */
      const portfolioLightbox = GLightbox({
        selector: '.portfokio-lightbox'
      });

      // const lightbox = GLightbox({ ...options });

      /**
       * Animation on scroll
       */
      function aos_init() {
        AOS.init({
          duration: 1000,
          easing: "ease-in-out",
          once: true,
          mirror: false
        });
      }
      window.addEventListener('load', () => {
        aos_init();
      });
    }
  }
}
</script>

<style scoped>

  section {
    margin: 64px 0 64px 0;
  }
  
  .line-items {
    font-weight: 300;
  }

  .total {
    font-weight: bold;
  }

  .portfolio-description {
    margin-right: 16px;
  }
  #price-cal-side {
    height: 50%;
    width: 40%;
  }
</style>
