<template>
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12 footer-info">
              <a href="index.html" class="logo d-flex align-items-center">
                <img src="assets/img/logo.png" alt="">
                <span>Up&Coming</span>
              </a>
              <p>Invest in real estate globally. Buy properties around the world, starting in Canada, Vietnam and the U.S.A.</p>
              <!-- <div class="social-links mt-3">
                <a href="https://twitter.com/bidllion" class="twitter" target="_blank" rel="noopener noreferrer" ><i class="bi bi-twitter"></i></a>
                <a href="https://www.facebook.com/bidllion" class="facebook" target="_blank" rel="noopener noreferrer" ><i class="bi bi-facebook"></i></a>
                <a href="https://www.instagram.com/bidllion" class="instagram"  target="_blank" rel="noopener noreferrer" ><i class="bi bi-instagram"></i></a>
                <a href="https://www.linkedin.com/company/bidllion" class="linkedin"  target="_blank" rel="noopener noreferrer" ><i class="bi bi-linkedin"></i></a>
              </div> -->
            </div>

            <div class="col-lg-2 col-6 footer-links hidden-mobile">
              <!-- This is to offset the Useful Links Div. -->
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i class="bi bi-chevron-right"></i> <a href="/">Home</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="/#contact">Contact</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="/#listings">Listings</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="/#faq">FAQ</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="/terms">Terms of Service</a></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                Palo Alto, CA 94301<br>
                United States <br><br>
                <strong>Phone:</strong> +1 (650) 687-7564 <br>
                <strong>Email:</strong> info@upandcoming.ai<br>
              </p>

            </div>

          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          &copy; Copyright <strong><span>Up & Coming</span></strong>. All Rights Reserved
        </div>
      </div>
    </footer><!-- End Footer -->
</template>

<style scoped>
  @media screen and (max-width: 768px) {
    .hidden-mobile {
      visibility: hidden;
      display: none;
    }
  }
</style>